@import 'colors';
@import '~@tabler/core/dist/css/tabler';
//@import '~@tabler/icons/iconfont/tabler-icons';

@import '~toastr';

.no-select {
    user-select: none;
}

.turbo-progress-bar {
    height: 2px;
    background-color: $primary;
}

.text-right {
    text-align: right !important;
}

.text-center {
    text-align: center !important;
}

.border-left {
    border-left: 1px solid #e6e8e9;
}

.border-right {
    border-right: 1px solid #e6e8e9;
}

.input-group-prepend .input-group-text {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.input-group-append .input-group-text {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

input[type=radio] + .form-check-label.required:after {
    content: '';
}

.print-invoice .logo {
    img {
        height: 50px;
        width: auto;
    }

    span {
        font-size: 25px;
        color: #666;

        position: relative;
        top: 10px;

        &.green {
            color: $primary;
        }
    }
}

@media print {
    @page {
        size: auto;
        margin: 0;
    }

    .print-invoice {
        padding: 1cm 1.5cm;
        page-break-after: always;
    }
}