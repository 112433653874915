$primary-base:  #321fdb !default;
$primary-100:   #eae9fb !default;
$primary-200:   #d6d2fb !default;
$primary-300:   #c1bcf4 !default;
$primary-400:   #ada5f1 !default;
$primary-500:   #988fed !default;
$primary-600:   #8478ea !default;
$primary-700:   #6f62e6 !default;
$primary-800:   #5b4ce2 !default;
$primary-900:   #4635df !default;

$primary-dark:  #1f1498 !default;
$primary:       #64b94d !default;
$primary-50:    #988fed !default;
$primary-25:    #ccc7f6 !default;

$secondary-base:  #3c4b64 !default;

$secondary-100:   #ebedef !default;
$secondary-200:   #d8dbe0 !default;
$secondary-300:   #c4c9d0 !default;
$secondary-400:   #b1b7c1 !default;
$secondary-500:   #9da5b1 !default;
$secondary-600:   #8a93a2 !default;
$secondary-700:   #768192 !default;
$secondary-800:   #636f83 !default;
$secondary-900:   #4f5d73 !default;

$secondary-dark:  #212233 !default;
$secondary:       #f9ae31 !default;
$secondary-50:    #9da5b1 !default;
$secondary-25:    #ced2d8 !default;
